<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3>Permissões de acesso de <span class="font-weight-bold" >{{perfilNome}}</span></h3>
      </div>
    </div>
    <div class="card-body">
      <div class="row justify-content-around">
        <div class="col-5 py-1"  v-for="(item, index) in permissoes" :key="index">
          <Accordion :title="item.modulo_nome">
            <div class="row pt-3 bg-ice" v-for="(permissao, indexpermissao) in item.permissoes" :key="indexpermissao">
              <div class="col-6">
                {{permissao.permissao_nome}}
              </div>
              <div class="col-6 float-right">
                <div class="form-group">
                  <select class="form-control" v-model="permissao.permissao_nivel_acesso">
                    <option value="0">Sem acesso</option>
                    <option value="1">Visualizar</option>
                    <option value="2">Visualizar/Editar</option>
                    <option value="3">Visualizar/Editar/Excluir</option>
                  </select>
                </div>
              </div>
            </div>
          </Accordion>
        </div>
        
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-5">
        <button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
        <button type="button" class="btn btn-primary float-right" @click="salvarPermissao">SALVAR</button>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from './../../../../uicomponents/Accordion'
import perfilRepository from './../../../../services/api/perfilAcessoRepository'
export default {
  components: {
    Accordion
  },
  created () {
		if (this.$route.query.idPerfil) {
      this.idPerfil = this.$route.query.idPerfil
			this.buscaPermissoes(this.$route.query.idPerfil)
		}
  },
  data () {
    return {
      permissoes: [],
      idPerfil: 0,
      perfilNome: ''
    }
  },
  methods: {
    buscaPermissoes(idPerfil) {
      perfilRepository.buscaPerfilAcessoPermissoes(idPerfil).then(response => {
        if (response.data['success']) {
          this.permissoes  = response.data['data']
          this.perfilNome = this.permissoes[0].perfil_nome
        }
      })      
    },
    salvarPermissao () {
      // OBJETO QUE VAI SETAR AS NOVAS PERMISSOES
      let permissoes = {
        id_perfil: this.idPerfil,
        permissoes_modulos: []
      }

      // PROCESSA O FORMATO DE PERMISSOES PARA ENVIAR OS DADOS DE FORMA ORGANIZADA
      this.permissoes.map(obj => {
        obj.permissoes.map(item => {
          let permissao = {
            acesso_modulo_permissao_id: item.permissao_id,
            nivel_acesso: item.permissao_nivel_acesso
          }
          permissoes.permissoes_modulos.push(permissao)
        })
      })

      perfilRepository.salvarPerfilAcessoPermissoes(permissoes).then(response => {
        if (response.data['success']) {
          this.$swal({
            icon: 'success',
            text: 'Cadastro atualizado com sucesso!'
          })
        }
      })
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'PerfilAcesso' })
    },
    isOdd (index) {
      if (index % 2 === 0) {
        return true
      }
    }
  }
}
</script>

<style>
  .bg-ice {
    background-color: rgba(0,0,0,.015);
  }
</style>