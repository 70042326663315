<template>
  <div class="row accordion">
      <div class="bg-dark col-12">
          <div class="header-accordion text-white p-2 animated fadeIn faster" @click="active = !active">
              <strong>{{title}}</strong>
              <i class="fa fa-2x fa-angle-down header-icon float-right" :class="{ rotate: active }"></i>
          </div>
      </div>
      <transition name="accordion"
        :before-enter="beforeEnter" :enter="enter"
        :before-leave="beforeLeave" :leave="leave">
        <div class="col-12 body" v-show="active" :class="{'animated fadeIn faster' : active}">
          <slot />
        </div>
      </transition>
  </div>
</template>

<script>
export default {
props: [
    'title'
  ],
  data() {
    return {
      active: false,
    }
  },
  methods: {
    beforeEnter: function(el) {
      el.style.height = '0';
    },
    enter: function(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    beforeLeave: function(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    leave: function(el) {
      el.style.height = '0';
    }
  }
}
</script>

<style>
.header-accordion:hover {
  cursor: pointer;
}
.accordion .header-icon.rotate {
  transform: rotate(180deg);
  transition-duration: 0.3s;
}
.accordion .header-icon {
  position: absolute;
  top: 5px;
  right: 8px;
  transform: rotate(0deg);
  transition-duration: 0.3s;
}
.accordion .body {
/*   display: none; */
  overflow: hidden;
  transition: 0.3s ease all;
}
</style>